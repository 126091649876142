<template>
<div>
  <v-container>
    <v-btn @click="shelfDialog.data = shelfBase(); shelfDialog.show = true" color="green" dark>
      <v-icon small>mdi-plus</v-icon>
      Add new Shelf
    </v-btn>
    <br>
    <br>
    <div>
      <div v-for="shelf in shelfs" :key="shelf.id" class="mb-1">
        <v-card outlined>
          <v-card-text class="d-flex align-center">
            <v-checkbox v-model="selectedShelfs" multiple :value="shelf"/>
            <div class="font-weight-black">
              <ShelfName :shelf="shelf" />
            </div>
            <v-spacer />
            <div>
              <div>
                <v-btn color="yellow lighten-2" small :to="{name:'products',query:{shelf:shelf.id}}">
                  <v-icon small>mdi-format-list-bulleted</v-icon>
                  Products
                </v-btn>
                <v-btn color="orange lighten-2" dark small @click="printLabels([shelf])">
                  <v-icon small>mdi-barcode</v-icon>
                  Print label
                </v-btn>
                <v-btn @click="shelfDialog.data = shelf; shelfDialog.show = true" color="blue lighten-2" dark small>
                  <v-icon small>mdi-pen</v-icon>
                  Edit
                </v-btn>
                <v-btn @click="shelfDialog.data = shelfBase(); shelfDialog.data.parent.id = shelf.id; shelfDialog.show = true" color="green lighten-2" dark small>
                  <v-icon small>mdi-plus</v-icon>
                  Add sub-shelf
                </v-btn>
                <v-btn @click="removeShelf(shelf)" color="red lighten-2" dark small>
                  <v-icon small>mdi-delete</v-icon>
                  Remove
                </v-btn>
              </div>
              <div class="text-right mt-1">
                <span>Total products: <strong>{{ shelf.productCountWithChildren }}</strong>, in this shelf: <strong>{{ shelf.productCount }}</strong></span>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <div class="ml-15" v-if="shelf.children.length > 0">
          <div v-for="child in shelf.children" :key="child.id" class="mt-1">
            <v-card outlined>
              <v-card-text class="d-flex align-center">
                <v-checkbox v-model="selectedShelfs" multiple :value="child"/>
                <div class="font-weight-black">
                  <ShelfName :shelf="child" />
                </div>
                <v-spacer />
                <div>
                  <div>
                    <v-btn color="yellow lighten-2" small :to="{name:'products',query:{shelf:child.id}}">
                      <v-icon small>mdi-format-list-bulleted</v-icon>
                      Products
                    </v-btn>
                    <v-btn color="orange lighten-2" dark small @click="printLabels([child])">
                      <v-icon small>mdi-barcode</v-icon>
                      Print label
                    </v-btn>
                    <v-btn @click="shelfDialog.data = child; shelfDialog.show = true" color="blue lighten-2" dark small>
                      <v-icon small>mdi-pen</v-icon>
                      Edit
                    </v-btn>
                    <v-btn @click="removeShelf(child)" color="red lighten-2" dark small>
                      <v-icon small>mdi-delete</v-icon>
                      Remove
                    </v-btn>
                  </div>
                  <div class="text-right mt-1">
                    <span>Total products: <strong>{{ child.productCountWithChildren }}</strong>, in this shelf: <strong>{{ child.productCount }}</strong></span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
      <div class="text-right py-5">Total products: <strong>{{ shelfs.reduce((sum, s) => sum + s.productCountWithChildren, 0) }}</strong></div>
    </div>
    <div v-if="selectedShelfs.length > 0">
      Selected:
      <v-btn color="orange lighten-2" dark small @click="printLabels(selectedShelfs)">
        <v-icon small>mdi-barcode</v-icon>
        Print labels ({{ selectedShelfs.length }} labels)
      </v-btn>
    </div>
  </v-container>
  <div>
    <v-btn @click="printOtherLabels.show = true">Print add/subtract/clear labels</v-btn>
  </div>
  <v-dialog v-model="shelfDialog.show" max-width="600px" persistent>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>
        Edit shelf
      </v-toolbar-title>
      <v-spacer />
      <v-btn
          icon
          @click="shelfDialog.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card>
      <v-card-text class="py-5">
        <v-text-field v-model="shelfDialog.data.name" label="Name" />
        <v-select v-model="shelfDialog.data.parent.id" label="Parent" :items="[
            {text: 'ROOT', value: null},
            ...shelfs.map(x => ({text: x.name, value: x.id}))
        ]" />
        <v-btn @click="save">Save</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="printOtherLabels.show" max-width="400px">
    <v-toolbar>
      <v-toolbar-title>Print labels</v-toolbar-title>
      <v-spacer/>
      <v-btn icon @click="printOtherLabels.show=false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet class="pa-5">
      <v-simple-table>
        <thead>
        <tr>
          <th></th>
          <th>Label</th>
          <th>Labels count</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(ol, spk) in printOtherLabels.labels" :key="spk">
          <td>{{ spk + 1 }}.</td>
          <td class="text-no-wrap">{{ ol.name }}</td>
          <td>
            <v-text-field type="number" min="0" v-model="ol.qty" solo flat outlined dense hide-details/>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-btn @click="prntOL()">Print</v-btn>
    </v-sheet>
  </v-dialog>
</div>
</template>

<script>

import axios from "axios";
import functions from "../mixins/functions";
import ShelfName from "../components/ShelfName";

export default {
  name: "Shelfs",
  components: {ShelfName},
  data () {
    return {
      selectedShelfs: [],
      shelfs: [],
      shelfDialog: {
        data: this.shelfBase(),
        show: false
      },
      printOtherLabels: {
        labels: [
          {
            name: 'Add label',
            barcode: 'ADD',
            qty: 1
          },
          {
            name: 'Subtract label',
            barcode: 'SUBTRACT',
            qty: 1
          },
          {
            name: 'Clear label',
            barcode: 'CLEAR',
            qty: 1
          }
        ],
        show: false
      }
    }
  },
  created() {
    this.getShelfs()
  },
  mixins: [functions],
  methods: {
    printLabels (shelfs) {
      this.printBarcodes(shelfs.map(x => ({
        b: x.barcode,
        n: (x.parent.name ? x.parent.name + ' -> ' : '') + x.name
      })))
    },
    getShelfs () {
      this.$store.commit('showLoading');
      axios.get(this.globals.apiUrl + '/shelf/list').then((res) => {
        this.shelfs = res.data
        this.$store.commit('hideLoading');
      })
    },
    save () {
      axios.post(this.globals.apiUrl + '/shelf/save', this.shelfDialog.data).then(() => {
        this.shelfDialog.show = false
        this.getShelfs()
      }).catch((reason) => {
        if(reason.response && reason.response.data && reason.response.data.error)
        {
          this.notyf(reason.response.data.error, 'error')
        }
        else
        {
          this.notyf(reason.message, 'error')
        }
      })
    },
    prntOL () {
      this.printOtherLabels.show = false
      this.printBarcodes(this.printOtherLabels.labels.filter(x => x.qty > 0).map(x => ({b: x.barcode, q: x.qty, n: x.barcode})))
    },
    shelfBase () {
      return {
        id: null,
        name: '',
        parent: {
          id: null
        }
      }
    },
    removeShelf (shelf) {
      axios.post(this.globals.apiUrl + '/shelf/save', {
        ...shelf,
        _delete: true
      }).then(() => {
        this.getShelfs()
      })
    }
  }
}
</script>

<style scoped>

</style>
