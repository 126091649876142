<template>
  <span>
    <span class="font-weight-bold">{{ t1 }}</span>
    <span v-if="t2"><v-icon v-text="'mdi-arrow-right'"/></span>
    <span v-if="t2">{{ t2 }}</span>
  </span>
</template>

<script>
export default {
  name: "ShelfName",
  props: ['shelf'],
  computed: {
    t1 () {
      return this.shelf.parent.name || this.shelf.name
    },
    t2 () {
      return this.shelf.parent.name ? this.shelf.name : null
    }
  }
}
</script>

<style scoped>

</style>
